import React, {useState} from 'react'
import {Formik, Form, Field, ErrorMessage, FormikHelpers} from 'formik'
import {boolean, object, string} from 'yup'
import Button from './Button'
import ThanksMessage from './ThanksMessage'
import logo from '../images/logo-simple.svg'
import logoInv from '../images/logo-simple-inv.svg'

export interface FormFields {
  firstname: string
  lastname: string
  email: string
  phone: string
  message: string
  accepted: boolean
}

const rules = object({
  firstname: string().required(),
  lastname: string().required(),
  email: string().required().email(),
  phone: string(),
  message: string().required(),
  accepted: boolean().isTrue(),
})

export default function ContactForm() {
  const [submitted, setSubmitted] = useState<boolean>(false)

  const handleSubmit = async (values: FormFields, {resetForm}: FormikHelpers<FormFields>): Promise<void> => {
    const response = await fetch(`${process.env.GATSBY_FORM_HANDLE_URL}`, {
      method: 'POST',
      body: JSON.stringify(values),
    })

    if (response.ok) {
      resetForm()

      setSubmitted(true)
    }
  }

  return (
    <>
      <Formik<FormFields>
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          message: '',
          accepted: false,
        }}
        validationSchema={rules}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({errors, isSubmitting}) => (
          <Form>
            <div className="flex flex-col space-y-16">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="flex flex-col space-y-1">
                  <Field
                      type="text"
                      name="firstname"
                      placeholder="Vorname"
                      disabled={isSubmitting}
                      className="disabled:opacity-50 outline-none bg-transparent rounded-full border-2 border-ci-darker px-4 py-3"
                  />
                  {errors.firstname && (
                      <div className="text-sm text-red-500">
                        Pflichtfeld
                      </div>
                  )}
                </div>
                <div className="flex flex-col space-y-1">
                  <Field
                      type="text"
                      name="lastname"
                      placeholder="Nachname"
                      disabled={isSubmitting}
                      className="disabled:opacity-50 outline-none bg-transparent rounded-full border-2 border-ci-darker px-4 py-3"
                  />
                  {errors.lastname && (
                      <div className="text-sm text-red-500">
                        Pflichtfeld
                      </div>
                  )}
                </div>
                <div className="flex flex-col space-y-1">
                  <Field
                      type="email"
                      name="email"
                      placeholder="E-Mail"
                      disabled={isSubmitting}
                      className="disabled:opacity-50 outline-none bg-transparent rounded-full border-2 border-ci-darker px-4 py-3"
                  />
                  {errors.email && (
                      <div className="text-sm text-red-500">
                        Gitte geben Sie Ihre E-Mail an
                      </div>
                  )}
                </div>
                <div className="flex flex-col space-y-1">
                  <Field
                      type="text"
                      name="phone"
                      placeholder="Telefon"
                      disabled={isSubmitting}
                      className="disabled:opacity-50 outline-none bg-transparent rounded-full border-2 border-ci-darker px-4 py-3"
                  />
                  <small className="pl-4 opacity-50">optional</small>
                </div>
                <div className="flex flex-col space-y-1 md:col-span-2">
                  <Field
                      component="textarea"
                      name="message"
                      placeholder="Ihre Nachricht"
                      rows={5}
                      disabled={isSubmitting}
                      className="disabled:opacity-50 outline-none bg-transparent rounded-lg border-2 border-ci-darker px-4 py-3"
                  />
                  {errors.message && (
                      <div className="text-sm text-red-500">
                        Pflichtfeld
                      </div>
                  )}
                </div>
                <div className="flex flex-col space-y-8 md:flex-row md:space-y-0 md:space-x-8 md:col-span-2">
                  <div className="flex-1 flex space-x-4 items-start">
                    <Field
                        type="checkbox"
                        name="accepted"
                        id="accepted"
                        disabled={isSubmitting}
                        className="h-6 w-6 mt-2"
                    />
                    <div className="flex flex-col space-y-2">
                      <label htmlFor="accepted" className="mt-2">Ich habe die <a href="/datenschutz" target="_blank" className="underline">Datenschutzbestimmungen</a> gelesen und akzeptiere diese.</label>
                      {errors.accepted && (
                          <span className="text-red-500">
                            Bitte akzeptieren Sie die Datenschutzbestimmungen
                          </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center w-full">
                <Button
                    type="submit"
                    className="!py-2 !px-8"
                    disabled={isSubmitting}
                >
                  {isSubmitting ? 'sende...' : 'absenden'}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ThanksMessage
          visible={submitted}
          onClose={() => setSubmitted(false)}
      />
    </>
  )
}
