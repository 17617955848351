import React, {useEffect, useState} from 'react'
import Button from '../components/Button'
import ScrollLink from '../components/ScrollLink'
import GradientContainer from '../components/GradientContainer'
import Heading from '../components/Heading'
import Highlight from '../components/Highlight'
import Layout from '../components/Layout'
import Courses from '../components/Courses'
import FAQ from '../components/FAQ'
import ContactForm from '../components/ContactForm'
import Img from 'gatsby-image'

import logo from './../images/logo-simple.svg'
import logoInv from './../images/logo-simple-inv.svg'
import cards from './../images/cards.png'
import faq from './../images/faq.svg'
import {graphql, useStaticQuery} from 'gatsby'
import HorizontalBorder from '../components/HorizontalBorder'

export default function IndexPage() {
    const data = useStaticQuery(graphql`
        query {
            portrait: file(relativePath: {eq: "evapless_portrait.jpg"}) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            portraitSquare: file(relativePath: {eq: "evapless_square.jpg"}) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            dgpraec: file(relativePath: {eq: "dgpraec.png"}) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            dgbt: file(relativePath: {eq: "dgbt.png"}) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

  return (
      <Layout mainPage>
        <GradientContainer
            dark
            className="z-40"
        >
          <div className="flex flex-col items-center gap-16">
            <div className="w-[330px] h-[330px] rounded-full bg-gradient-to-br from-ci to-ci-darker p-2 shadow-xl">
              <Img fluid={data.portraitSquare.childImageSharp.fluid} alt="" className="w-full h-auto rounded-full"/>
            </div>
            <div className="flex text-center flex-col items-center gap-4">
              <Heading as="h1" outline>
                Dr. med. Eva Maria Pless
              </Heading>
              <span className="text-sm">
                Fachärztin für Plastische und Ästhetische Chirurgie
              </span>
            </div>
          </div>
        </GradientContainer>
        <HorizontalBorder/>
        <GradientContainer>
          <div className="flex flex-col md:flex-row items-center gap-8">
            <Heading className="flex justify-center flex-1 !text-ci-gray !text-2xl font-semibold">
              Deutsche Gesellschaft der plastischen, rekonstruktiven und ästhetischen Chirurgie
            </Heading>
            <div className="flex justify-center flex-1 h-[80px]">
              <img src={data.dgpraec.childImageSharp.fluid.srcWebp} className="h-[100px] w-auto" alt=""/>
            </div>
            <div className="flex justify-center flex-1 h-[80px]">
              <img src={data.dgbt.childImageSharp.fluid.srcWebp} className="h-[100px] w-auto" alt=""/>
            </div>
          </div>
        </GradientContainer>
        <HorizontalBorder/>
        <GradientContainer
            dark
            id="meine-philosophie"
        >
          <div className="flex flex-col gap-24">
            <div className="flex justify-center text-center">
              <Heading outline>Meine Philosophie</Heading>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-32 gap-y-16 md:gap-y-24">
              <div className="flex flex-col gap-4">
                <Heading
                    as="h3"
                    className="!text-2xl !text-ci-darker"
                >
                  Vertrauen
                </Heading>
                <p className="leading-6">
                  Großen Wert lege ich auf die persönliche Beratung, bei der für jeden Patienten ein individuelles Behandlungskonzept erstellt wird. Für Sie nehme ich mir viel Zeit, um Ihre Vorstellungen und Erwartungen mit dem medizinisch Machbaren
                  zu
                  besprechen. Sie werden von mir über die Möglichkeiten der Behandlung, deren Risiken und Komplikationen aufgeklärt – da nur ein bestens informierter und aufgeklärter Patient die richtigen Entscheidungen für sich treffen kann. Über
                  die
                  Verantwortung gesundheitliche, kosmetische und ästhetische Aspekte harmonisch miteinander zu verbinden bin ich mir bewusst.
                </p>
              </div>

              <div className="flex flex-col gap-4">
                <Heading
                    as="h3"
                    className="!text-2xl !text-ci-darker"
                >
                  Ästhetik
                </Heading>
                <p className="leading-6">
                  Schönheitsideale wandeln sich im Laufe der Zeit ebenso wie Gesicht und Körper des Menschen. Wir besitzen eine natürliche Schönheit, die betont, korrigiert oder rekonstruiert werden kann. Unser Selbstwertgefühl bestimmt, wer wir sind
                  und wie wir uns der Welt darstellen. Auch unser äußeres Erscheinungsbild trägt dazu bei und beeinflusst unser Wohlbefinden.
                </p>
              </div>

              <div className="flex flex-col gap-4">
                <Heading
                    as="h3"
                    className="!text-2xl !text-ci-darker"
                >
                  Kompetenz
                </Heading>
                <p className="leading-6">
                  Es sind nicht immer große Operationen, die eine wesentliche Verbesserung erzielen, sondern oft schonende Maßnahmen, die den normalen Lebensrhythmus kaum beeinträchtigen.
                </p>
              </div>

              <div className="flex flex-col gap-4">
                <Heading
                    as="h3"
                    className="!text-2xl !text-ci-darker"
                >
                  Qualität
                </Heading>
                <p className="leading-6">
                  Als Fachärztin für Plastische und Ästhetische Chirurgie steht Ihre Gesundheit für mich an erster Stelle. Mit hoher Fachkompetenz setze ich ethische und moralische Verantwortung ein, um Ihre Wünsche nach Veränderung zu
                  erfüllen. Wichtig ist für mich die Erhaltung Ihrer Natürlichkeit. Kein maskenhaftes Erscheinungsbild, sondern ein Gesicht voller Harmonie, dessen Proportionen stimmen. Das ist der Anspruch, den ich an meine Arbeit
                  stelle.
                </p>
              </div>
            </div>
          </div>
        </GradientContainer>
        <HorizontalBorder/>
        <GradientContainer id="qualifikation-und-ausbildung">
          <div className="flex flex-col gap-24">
            <div className="flex justify-center text-center">
              <Heading>Ausbildung & Qualifikation</Heading>
            </div>
            <div className="grid grod-cols-1 md:grid-cols-2 gap-20">
              <div>
                <Img fluid={data.portrait.childImageSharp.fluid} alt="" className="w-full h-auto rounded-xl shadow-container"/>
              </div>
              <ul className="content-list grid grid-cols-1 gap-4">
                <li>Studium der Humanmedizin an der Universität Köln</li>
                <li>Schon in dieser Zeit entdeckte sie ihre Liebe zur Plastischen Chirurgie als langjährige studentische Aushilfskraft in der Abt. für Plastische</li>
                <li>Chirurgie an der Universitätsklinik Köln bei Prof. Dr. J. Schrudde.</li>
                <li>1979 Approbation als Arzt</li>
                <li>1989 Facharzt für Chirurgie</li>
                <li>Nach Ausbildung an international renommierten Fachkliniken – Klinik für Plastische- und Wiederherstellungschirurgie, Handchirurgie, Schwerstverbranntenzentrum Köln-Merheim, und mehrjährige Ausbildung in der Abt. für Plastische und Ästhetische Chirurgie bei Prof. Dr. Neven Olivari 1993 die Anerkennung zum Führen der Gebietsbezeichnung „Plastische Chirurgie“.</li>
                <li>1994-1998 in der Abt. für Plastische und Ästhetische Chirurgie am Hospital Schlitzerland in Schlitz als Belegärztin niedergelassen.</li>
                <li>Ab 1999 als Fachärztin für Plastische und Ästhetische Chirurgie in eigener Praxis in Fulda niedergelassen.</li>
                <li>Durch ständige praktische und wissenschaftliche Fortbildung, sowie fachspezifische Aufenthalte im europäischen Ausland, USA und Lateinamerika hat sie sich eine Fachkompetenz auf nationalem und internationalem Niveau erworben und nach Abgabe der kassenärztlichen Vertragstätigkeit führte sie eine Privatpraxis für Plastische und Ästhetische Chirurgie in Fulda mit dem Schwerpunkt Ästhetische Gesichts- und Brustchirurgie.</li>
                {/*<li>Sie führt die ambulanten Operationen in ihrer Privatpraxis in Fulda und die stationären Operationen in der Privatklinik Triangulum Abt. für Plastische und Ästhetische Chirurgie in Gelnhausen durch.</li>*/}
              </ul>
            </div>
          </div>
        </GradientContainer>
        <HorizontalBorder/>
        <GradientContainer
            dark
            className="z-0"
            id="kontakt"
        >
          <div className="flex flex-col gap-24">
            <div className="flex flex-col items-center text-center gap-4">
              <Heading outline>
                Kontakt
              </Heading>
              <span className="text-sm">
                Ich freue mich auf Ihre Nachricht!
              </span>
            </div>

            <div className="max-w-[700px] mx-auto">
              <ContactForm/>
            </div>
          </div>
        </GradientContainer>
      </Layout>
  )
}
